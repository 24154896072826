import React,{useEffect,useState} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import bannerBg from '../../../assets/tondao/banner.png';
import bannerBg1 from '../../../assets/v2/bannerbg.png';
import wallet from '../../../assets/wallet.png';
import walletWhite from '../../../assets/wallet-white.png';

import { message } from 'antd';

export default ()=> {
	let titleLetter = "WHAT IS TONDAO?";
	let subTitleLetter = "TON DAO is a Web3 decentralized autonomous ecosystem platform built on autonomous protocols, with strong technical support as the underlying technology.It was started and built by a group of cryptocurrency geeks.";
	let titleMultiple = 200;
	let subTitleMultiple = 100;
	let delay = 500;
	const [title,settitle] = React.useState('');
	const [subTitle,setsubTitle] = React.useState('');
	
	const [messageApi, contextHolder] = message.useMessage();
	const openMessage = () => {
	    messageApi.open({
			type: 'info',
			content: 'COMING SOON',
			duration: 3,
		});
	};
	
	useEffect(() => {
		 AOS.init();
		 
		 // let emptyTitle = '';
		 // titleLetter.split("").forEach((item,index)=>{
			//  setTimeout(()=>{
			// 	 emptyTitle +=item;
			// 	 settitle(emptyTitle);
			//  },index*titleMultiple + delay)
		 // })
		 
		 // let emptySubTitle = '';
		 // subTitleLetter.split("").forEach((item,index)=>{
			//  setTimeout(()=>{
			// 	 emptySubTitle +=item;
			// 	 setsubTitle(emptySubTitle);
			//  },index*subTitleMultiple + (titleLetter.length+4) * titleMultiple + delay)
		 // })
		 
	}, [])
  return (
    <div className="relative flex min-h-screen bg-mainBg items-center">
    	<div className="max-w-full md:max-w-7xl mx-auto w-full h-full md:my-auto px-4 md:px-0 md:flex items-center">
    		<div className="z-20 relative max-w-full lg:w-2/3">
    			<main className="mt-12 mx-auto max-w-full lg:max-w-8xl sm:px-6 md:mt-0">
    				<div className="text-center md:text-left">
    					<h1 className="relative tracking-tight font-extrabold font-display  text-gray-900 text-3xl sm:text-4xl md:text-6xl lg:text-7xl xl:text-8xl">
    						<div className="block">WHAT IS</div>
							<div className="block mb-3"><span className="Gradient">TON</span> DAO?</div>
    					</h1>
    					<p className="mt-6 leading-2 text-base sm:text-lg md:text-xl sm:leading-12 text-gray-900 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0 ">
    						{subTitleLetter}
    					</p>
    					{/* <div className="mb-2 sm:mb-0 mt-5 sm:mt-8 flex justify-center md:justify-start">
    						<div className="mt-3 sm:mt-0 flex flex-col lg:flex-row">
    							<a href="https://finance.famdao.org/home" className="button flex justity-center items-center border cursor-pointer px-6 py-2 text-xs sm:text-base  rounded-full text-white md:py-2 cursor-pointer md:px-6 ">
    								TON DAO
									<img className="h-6 ml-2" src={walletWhite}/>
    							</a>
    						</div>
    					</div> */}
    				</div>
    			</main>
    		</div>
			{/* {contextHolder} */}
			<div className=" relative hidden md:block">
				<img className="w-1/2 md:w-4/5 mx-auto mt-12 md:mt-0 relative z-10" src={bannerBg}/>
				{/* <div className="run-shadow"></div> */}
			</div>
    	</div>
    	
    </div>
  );
}

