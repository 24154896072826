import React,{useState} from 'react'

import Banner from './Banner'
import Carousel from './Carousel'
import Why from './Why'
import Vision from './Vision'
import Center from './Center'
import Ecosystem from './Ecosystem'
import RoadMap from './RoadMap'
import Tokenomics from './Tokenomics'
import SDK from './SDK'
import FAQ from './FAQ'
import News from './News'
// import Partners from './Partners'


export default ()=> {
  return (
	<div className="main">
		<Banner />
		<Vision />
		{/* <RoadMap />
		<Why />
		<News /> */}
		{/* <Partners /> */}
	</div>
  );
}

