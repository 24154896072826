import React,{useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import vision from '../../../assets/tondao/pic2.png';

export default ()=> {
	useEffect(() => {
		 AOS.init();
	}, [])
  return (
    <div className="">
    	<div className="w-full pb-12">
    		<div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-6">
    			<div className="lg:flex justify-center items-center">
					<img  data-aos="zoom-in" className="p-4 h-auto w-full lg:h-auto lg:w-1/2 mx-auto" src={vision}/>
    				<div className="text-center lg:text-left">
						<div className="text-3xl sm:text-4xl md:text-6xl  text-gray-900">ABOUT TON DAO</div>
						<div className="mt-8 text-base sm:text-lg md:text-xl  text-gray-900">
						TON DAO was originally launched by a group of crypto geeks and is essentially a decentralized governance organization (DAO). The value it creates flows to token holders and back to the ecosystem, promoting a virtuous cycle in the ecosystem.All Web3 builders can gather together and collaborate to build different ecological applications.
						<br/>
						Governance tokens (TOD) give holders the right to propose proposals.
						</div>
					</div>
    			</div>
    			
    		</div>
    	</div>
    </div>
  );
}

